import {
    SET_SHURUQ,
    SET_IQAMA_APP,
    SET_ADHAN_APP,
    SET_THIRD_NIGHT,
    SET_ADHKAR_SALAT,
    SET_MIDDLE_NIGHT,
    SET_DARK_MODE_APP,
    SET_CACHING_STATUS,
    SET_FOCUS_MODE_APP,
    SET_DARK_MODE_APP_INIT
} from '../actions/types';

const initialState = {
    isAdhan: { status: false, name: '' },
    isIqama: { status: false, name: '' },
    isShuruq: false,
    darkMode: null,
    focusMode: false,
    adhkarSalat: false,
    isThirdNight: false,
    cachingStatus: true,
    isMiddleNight: false,
    inAppBrightness: false,
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;

    switch ( type ) {
        case SET_ADHAN_APP:
            return {
                ...state,
                isAdhan: payload,
            };
        case SET_IQAMA_APP:
            return {
                ...state,
                isIqama: payload,
            };
        case SET_SHURUQ:
            return {
                ...state,
                isShuruq: payload,
            };
        case SET_THIRD_NIGHT:
            return {
                ...state,
                isThirdNight: payload,
            };
        case SET_MIDDLE_NIGHT:
            return {
                ...state,
                isMiddleNight: payload,
            };
        case SET_FOCUS_MODE_APP:
            return {
                ...state,
                focusMode: payload,
            };
        case SET_ADHKAR_SALAT:
            return {
                ...state,
                adhkarSalat: payload,
            };
        case SET_DARK_MODE_APP:
        case SET_DARK_MODE_APP_INIT:
            return {
                ...state,
                darkMode: payload,
            };
        case SET_CACHING_STATUS:
            return {
                ...state,
                cachingStatus: payload,
            };
        default:
            return state;
    }
}
