import {
    GET_TV_CODE,
} from '../actions/types';


const initialState = {
    loading: true,
    code: null,
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;
    switch ( type ) {

        case GET_TV_CODE:
            return {
                ...state,
                code: payload.code,
                loading: false,
            }

        default:
            return state;
    }

}