/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !                                                                    !
 * !               🚫 REDUX STORE CREATION FALLBACK 🚫                  !
 * !                                                                    !
 * !  This code provides a fallback mechanism for creating a Redux      !
 * !  store, supporting both modern (configureStore) and legacy         !
 * !  (createStore) methods. It ensures compatibility with older        !
 * !  browsers while leveraging modern practices where possible.        !
 * !                                                                    !
 * !  Target browsers: Chrome 45+, Firefox 40+, Safari 9+, IE 11+       !
 * !                                                                    !
 * !  ⚠️  Modify with caution to maintain broad browser support.  ⚠️    !
 * !                                                                    !
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
import { createStore, compose, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from './reducers';
import { configureStore as reduxToolkitConfigureStore } from '@reduxjs/toolkit'; // Static import for modern browsers

export default function setupStore ( preloadedState ) {
    if ( reduxToolkitConfigureStore ) {
        // Use configureStore from Redux Toolkit if available
        return reduxToolkitConfigureStore( {
            reducer: rootReducer,
            preloadedState,
            middleware: ( getDefaultMiddleware ) =>
                getDefaultMiddleware().concat( thunk ),
        } );
    } else {
        // Fallback to createStore for older browsers
        const composeEnhancers =
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        return createStore(
            rootReducer,
            preloadedState,
            composeEnhancers( applyMiddleware( thunk ) )
        );
    }
}
