import {
    DISABLE_EXTERNAL_CONTROL,
    ENABLE_EXTERNAL_CONTROL,
    LOAD_PLAYLIST_EXTERNAL,
    UPDATE_CURRENT_MEDIA,
    INCREASE_VOLUME,
    DECREASE_VOLUME,
    TOGGLE_LOOPING,
    CLEAR_PLAYLIST,
    PREVIOUS_MEDIA,
    LOAD_PLAYLIST,
    TOGGLE_PLAY,
    TOGGLE_MUTE,
    PAUSE_MEDIA,
    PLAY_MEDIA,
    STOP_MEDIA,
    NEXT_MEDIA,
} from '../actions/types';

// Initial state of the player
const initialPlayerState = {
    isExternalControlEnabled: false,
    currentCommentIndex: -1,
    currentMediaType: null,
    currentMediaIndex: 0,
    keepPlaying: false,
    playing: false,
    playlist: [],
    volume: 1.0,
    muted: true,
    length: 0,
    type_AUDIO: false,
    type_VIDEO: false,
    type_TEXT: false,
    type_IMAGE: false,
    isThread: false,
    actualPost: [],
};

// Helper function to determine the media types in a post
const setCurrentTypes = ( post ) => {
    const type_AUDIO = post.media.some( media => media.type === 'audio' );
    const type_VIDEO = post.media.some( media => media.type === 'video' ) || ( post.isLink && post.isLinkVideo && post.link );
    const type_IMAGE = post.media.some( media => media.type === 'image' );
    const type_TEXT = post.text && post.text.length > 0;
    return { type_AUDIO, type_VIDEO, type_IMAGE, type_TEXT };
};

// Helper function to get the next media index and comment index
const getNextIndex = ( state ) => {
    let newIndex = state.currentMediaIndex;
    let newCommentIndex = state.currentCommentIndex;
    const currentPost = state.playlist[ newIndex ];

    if ( state.isThread ) { // If current post is a thread
        if ( newCommentIndex < currentPost.comments.length - 1 ) {
            newCommentIndex++; // Move to the next comment
        } else {
            newCommentIndex = -1; // Reset comment index
            newIndex = ( newIndex + 1 ) % state.playlist.length; // Move to the next post
        }
    } else {
        newIndex = ( newIndex + 1 ) % state.playlist.length; // Move to the next post
        if ( state.playlist[ newIndex ].isThread ) {
            newCommentIndex = 0; // Start from the first comment if it's a thread
        }
    }

    return { newIndex, newCommentIndex };
};


// Helper function to get the previous media index and comment index
const getPreviousIndex = ( state ) => {
    let newIndex = state.currentMediaIndex;
    let newCommentIndex = state.currentCommentIndex;

    if ( state.isThread ) { // If current post is a thread
        if ( newCommentIndex > 0 ) {
            newCommentIndex--; // Move to the previous comment
        } else {
            newCommentIndex = -1; // Reset comment index
            newIndex = ( newIndex - 1 + state.playlist.length ) % state.playlist.length; // Move to the previous post
        }
    } else {
        newIndex = ( newIndex - 1 + state.playlist.length ) % state.playlist.length; // Move to the previous post
        if ( state.playlist[ newIndex ].isThread ) {
            newCommentIndex = state.playlist[ newIndex ].comments.length - 1; // Start from the last comment if it's a thread
        }
    }

    return { newIndex, newCommentIndex };
};

// Reducer function to handle player actions
const playerReducer = ( state = initialPlayerState, action ) => {
    switch ( action.type ) {
        case LOAD_PLAYLIST:
            const firstPostTypes = setCurrentTypes( action.payload.posts[ 0 ] || {} );
            return {
                ...state,
                playlist: action.payload.posts,
                length: action.payload.posts.length,
                currentMediaIndex: 0,
                currentCommentIndex: action.payload.posts[ 0 ]?.isThread ? 0 : -1,
                ...firstPostTypes,
                playing: action.type === LOAD_PLAYLIST_EXTERNAL,
                isExternalControlEnabled: action.type === LOAD_PLAYLIST_EXTERNAL,
                isThread: action.payload.posts[ 0 ]?.isThread || false,
                actualPost: action.payload.posts[ 0 ]?.isThread ? action.payload.posts[ 0 ].comments : [ action.payload.posts[ 0 ] ],
            };

        case UPDATE_CURRENT_MEDIA:
            const newIndex = action.payload % state.playlist.length;
            const newPost = state.playlist[ newIndex ];
            const newPostTypes = setCurrentTypes( newPost );
            return {
                ...state,
                currentMediaIndex: newIndex,
                currentCommentIndex: newPost.isThread ? 0 : -1,
                ...newPostTypes,
                playing: state.isExternalControlEnabled || state.keepPlaying || newIndex !== 0,
                isThread: newPost.isThread || false,
                actualPost: newPost.isThread ? newPost.comments : [ newPost ],
            };

        // playerReducer.js
        case NEXT_MEDIA:
            const { newIndex: nextIndex, newCommentIndex: nextCommentIndex } = getNextIndex( state );
            const nextPost = state.playlist[ nextIndex ];
            const nextIsThreadFinished = nextCommentIndex === -1 && state.isThread;
            // Determine the next post or comment to display
            const nextPostOrComment = nextIsThreadFinished ? nextPost : ( nextCommentIndex >= 0 ? nextPost.comments[ nextCommentIndex ] : nextPost );
            const nextPostTypes = setCurrentTypes( nextPostOrComment );
            const actualNextPost = nextIsThreadFinished ? nextPost : nextPostOrComment;
            const audioType = nextPostTypes.type_AUDIO;
            return {
                ...state,
                currentMediaIndex: nextIndex,
                currentCommentIndex: nextCommentIndex,
                ...nextPostTypes,
                isThread: nextIsThreadFinished ? false : ( nextPost.isThread || false ),
                actualPost: actualNextPost,
                playing: !nextIsThreadFinished || audioType, // Stop playing when not a thread and at the end
            };

        case PREVIOUS_MEDIA:
            const { newIndex: prevIndex, newCommentIndex: prevCommentIndex } = getPreviousIndex( state );
            const prevPost = state.playlist[ prevIndex ];
            const prevIsThreadFinished = prevCommentIndex === -1 && state.isThread;
            // Determine the previous post or comment to display
            const prevPostOrComment = prevIsThreadFinished ? prevPost : ( prevCommentIndex >= 0 ? prevPost.comments[ prevCommentIndex ] : prevPost );
            const prevPostTypes = setCurrentTypes( prevPostOrComment );
            const actualPrevPost = prevIsThreadFinished ? prevPost : prevPostOrComment;

            return {
                ...state,
                currentMediaIndex: prevIndex,
                currentCommentIndex: prevCommentIndex,
                ...prevPostTypes,
                isThread: prevIsThreadFinished ? false : ( prevPost.isThread || false ),
                actualPost: actualPrevPost,
                playing: !prevIsThreadFinished && prevIndex > 0, // Stop playing when not a thread and at the beginning
            };

        case PLAY_MEDIA:
        case TOGGLE_PLAY:
        case PAUSE_MEDIA:
        case STOP_MEDIA:
            const currentPost = state.playlist[ state.currentMediaIndex ];
            const currentPostTypes = setCurrentTypes( currentPost );

            return {
                ...state,
                playing: action.type === TOGGLE_PLAY ? action.payload : ( action.type !== PAUSE_MEDIA && action.type !== STOP_MEDIA ),
                currentMediaIndex: action.type === STOP_MEDIA ? 0 : state.currentMediaIndex,
                currentCommentIndex: action.type === STOP_MEDIA ? -1 : state.currentCommentIndex,
                ...currentPostTypes,
                isThread: currentPost.isThread || false,
                actualPost: currentPost.isThread ? currentPost.comments : [ currentPost ],
            };

        case TOGGLE_MUTE:
        case INCREASE_VOLUME:
        case DECREASE_VOLUME:
            return {
                ...state,
                muted: action.type === TOGGLE_MUTE ? !state.muted : state.muted,
                volume: action.type === INCREASE_VOLUME ? Math.min( 1, state.volume + 0.1 ) :
                    action.type === DECREASE_VOLUME ? Math.max( 0.1, state.volume - 0.1 ) : state.volume,
            };

        case ENABLE_EXTERNAL_CONTROL:
        case DISABLE_EXTERNAL_CONTROL:
            return {
                ...state,
                isExternalControlEnabled: action.type === ENABLE_EXTERNAL_CONTROL,
            };

        case TOGGLE_LOOPING:
            return {
                ...state,
                keepPlaying: !state.keepPlaying, // Toggle the looping behavior
            };

        case CLEAR_PLAYLIST:
            // Reset the state to the initial state
            return {
                ...initialPlayerState,
            };

        default:
            return state;
    }
};

export default playerReducer;
