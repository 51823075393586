import { SET_LANGUAGE, CHANGE_LANGUAGE } from '../actions/types';

const initialState = {
    currentLanguage: 'en', // Default language
};

export default function languageReducer ( state = initialState, action ) {
    const { type, payload } = action;

    switch ( type ) {
        case SET_LANGUAGE:
            // Set the initial language without changing the fade class
            return {
                ...state,
                currentLanguage: payload
            };
        case CHANGE_LANGUAGE:
            // Change the language and manage fade animations
            return {
                ...state,
                currentLanguage: payload.newLanguage,
            };
        default:
            return state;
    }
}
