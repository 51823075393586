import { combineReducers } from "redux";
import globalLoading from "./globalLoading";
import appStatus from "./appStatus";
import language from "./language";
import prayers from "./prayers";
import player from "./player";
import admin from "./admin";
import flyer from "./flyer";
import alert from "./alert";
import tv from "./tv";

export default combineReducers( {
    globalLoading,
    appStatus,
    language,
    prayers,
    player,
    alert,
    admin,
    flyer,
    tv
} );
