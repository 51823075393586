import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ar from './services/locales/ar.json';
import ur from './services/locales/ur.json';
import bn from './services/locales/bn.json';
import en from './services/locales/en.json';
import fr from './services/locales/fr.json';
import ja from './services/locales/ja.json';

const Languages = [ 'en', 'fr', 'ar', 'ur', 'bn', 'ja' ];

i18n
    .use( initReactI18next )
    .init( {
        compatibilityJSON: 'v3',
        fallbackLng: 'en',
        lng: localStorage.getItem( 'lang' ) || 'en',
        preload: [ 'ar', 'en' ],
        initImmediate: false,
        debug: true,
        resources: {
            ar: { translation: ar },
            ur: { translation: ur },
            bn: { translation: bn },
            en: { translation: en },
            fr: { translation: fr },
            ja: { translation: ja },
        },
        supportedLngs: Languages,
        interpolation: {
            escapeValue: false,
        },
    } );

export default i18n;
