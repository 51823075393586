import {
    GET_FLYER,
} from '../actions/types';


const initialState = {
    data: [],
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;
    switch ( type ) {
        case GET_FLYER:
            return {
                ...state,
                data: payload,
            };

        default:
            return state;
    }

}