/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !                        🚫 OLD BROWSER SUPPORT 🚫                                                                   !
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
import 'regenerator-runtime/runtime';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/actual/array/from';
import 'core-js/actual/array/group';
import 'core-js/actual/set';
import 'core-js/actual/promise';
import 'core-js/actual/structured-clone';
import 'core-js/actual/queue-microtask';
import 'core-js/es/array/includes';
import 'core-js/es/array/from';
import 'core-js/es/object/assign';
import 'core-js/es/string/includes';
import 'core-js/es/promise';
import 'core-js/es/symbol';
import 'core-js/es/weak-map';
import 'core-js/es/weak-set';
/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !                        🚫 OLD BROWSER SUPPORT 🚫                                                                   !
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */


import App from './App';
import React from 'react';
import './styles/fonts/font.min.css';
import ReactDOM from 'react-dom/client';
import ErrorBoundary from './views/app/errors';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ErrorBoundary>
  </React.StrictMode>
);