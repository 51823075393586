import {
    SET_GLOBAL_LOADING,
} from '../actions/types';


const initialState = {
    isGlobalLoading: true,
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;
    switch ( type ) {

        case SET_GLOBAL_LOADING:
            return {
                ...state,
                isGlobalLoading: false,
            }

        default:
            return state;
    }

}