import React from 'react';

class ErrorBoundary extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { hasError: false };
    }

    static getDerivedStateFromError ( error ) {
        // Update state so the next render shows the fallback UI.
        return { hasError: true };
    }

    render () {
        if ( this.state.hasError ) {
            return <div>Something went wrong. Please try again later.</div>; // Custom fallback UI
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
